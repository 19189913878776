/**
 * Archivo: index.jsx
 * Sistema: CMR
 * Módulo: Logos Abajo
 * Responsables: NATALIA MOSQUERA - JOSE LUIS CONDORI
 * Programador: Rodrigo Andrade
 * Última modificación: 14 de marzo del 2025
 * Descripción: Componente que muestra un carrusel de logos de aseguradoras en la parte inferior.
 */

import React from "react"; // Importación de React para utilizar JSX
// Estilos
import Container from "./styled"; // Importación del contenedor estilizado
// Librerías
import Marquee from "react-fast-marquee"; // Librería para la animación de desplazamiento de logos
// Utils
import useWindowDimensions from "../../../../utils/useWindowDimensions"; // Hook personalizado para obtener dimensiones de la ventana

export default function LogosAbajo() {
  // Obtiene las dimensiones actuales de la ventana
  const { width } = useWindowDimensions();

  return (
    <Container>
      {/* Contenedor principal de la sección  */}
      <section id="max-width-container">
         {/* Contenedor del carrusel de logos  */}
        <div id="logo-slider">
          <Marquee
            // Si la pantalla es menor o igual a 850px, se desactiva el degradado
            gradient={width <= 850 ? false : true}
            loop={0} // Loop infinito
            gradientColor={[247, 249, 254]} // Color del degradado de fondo
            speed={17} // Velocidad de desplazamiento
            direction="right" // Dirección del desplazamiento (de derecha a izquierda)
          >
            {/* Lista de logos de aseguradoras */}
            <div className="logo" id="nacion-seguros">
              <img
                alt="Nación Seguros Logo"
                src="/logos/aseguradoras/nacion-seguros.svg"
              />
            </div>

            <div className="logo" id="provincia-seguros">
              <img
                alt="Prudencia Seguros Logo"
                src="/logos/aseguradoras/provincia-seguros.svg"
              />
            </div>

            <div className="logo" id="federacion-patronal-seguros">
              <img
                alt="Logo de Federación Patronal Seguros"
                src="/logos/aseguradoras/federacion-patronal-seguros.png"
              />
            </div>

            {/* Repetición de los logos para dar continuidad al carrusel */}
            <div className="logo" id="nacion-seguros">
              <img
                alt="Nación Seguros Logo"
                src="/logos/aseguradoras/nacion-seguros.svg"
              />
            </div>

            <div className="logo" id="provincia-seguros">
              <img
                alt="Prudencia Seguros Logo"
                src="/logos/aseguradoras/provincia-seguros.svg"
              />
            </div>

            <div className="logo" id="provincia-seguros">
              <img
                alt="Prudencia Seguros Logo"
                src="/logos/aseguradoras/provincia-seguros.svg"
              />
            </div>

            <div className="logo" id="federacion-patronal-seguros">
              <img
                alt="Logo de Federación Patronal Seguros"
                src="/logos/aseguradoras/federacion-patronal-seguros.png"
              />
            </div>
          </Marquee>
        </div>
      </section>
    </Container>
  );
}


