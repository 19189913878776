import axios from "axios"
import url from "../utils/url";

export const postearAnulacion = async (data) => {
    return await axios.post(`${url}/api/baja`, data);
}

export const getMotivosBaja = async () => {
    return await axios.get(`${url}/api/baja/motivos`);
}

export const getCamposProductoAnulacion = async (id_producto) => {
    return await axios.get(`${url}/api/baja/campos/producto/${id_producto}`);
}

export const obtenerProductosCalidad = async () => {
    return await axios.get(`${url}/api/baja/productos`);
}