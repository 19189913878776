import styled from "styled-components";
import { breakpoints } from "../../../constants/media";

const Container = styled.div`
  width: 100%;
  //margin: 48px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;

  .sigCanvas {
    background-color: #fff;
    border: 1.7px solid var(--gris80);
  }

  // Formulario
  form {
    width: 100%;
    max-width: 1140px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
  }

  // 1200px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1200}) {
    #max-width-coberturas-container {
      max-width: 680px;
    }

    // Coberturas Container
    .coberturas-container {
      max-width: 290px;
      margin: 40px 0px 0px 0px;
    }

    // Checks Container
    .checks-container {
      width: 150px;
    }

    // Checks Container Span
    .checks-container span {
      display: none;
    }

    // Formulario
    form {
      max-width: 680px;
    }

    // Input Container, Select Container, Input Código Area, Input Telefono y Input Vencimiento
    .input-container,
    .select-container,
    #input-codigo-area-container,
    #input-telefono-container,
    #input-vencimiento-container {
      width: 216.5px;
      max-width: 216.5px;
    }

    // Input Código Postal
    #input-codigo-postal-container,
    #input-color-container {
      margin: 30px 15px 0px 0px;
    }

    // Input CUIT DNI, Input Telefono y Código de Área Container
    #input-cuit-dni-container,
    #input-telefono-container,
    #input-codigo-area-container,
    #input-rodado-container,
    #input-transmision-container,
    #input-vencimiento-container {
      margin: 30px 0px 0px 0px;
    }

    // Datos Tarjeta
    #datos-tarjeta-credito {
      width: 100% !important;
    }
  }

  // 850px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet850}) {
    // Max Width Coberturas Container
    #max-width-coberturas-container {
      max-width: 480px;
    }

    // Formulario
    form {
      max-width: 480px;
    }

    // Datos Vehiculo Container
    .datos-vehiculo-container {
      width: 100%;
    }

    // Input Container y Select Container, Input CUIT DNI, Input Código Área, Input Vencimiento, Input Telefono
    .input-container,
    .select-container,
    #input-cuit-dni-container,
    #input-codigo-area-container,
    #input-vencimiento-container,
    #input-telefono-container,
    #input-rodado-container {
      width: 232.5px;
      max-width: 232.5px;
      margin: 30px 15px 0px 0px;
    }

    // Input Apellido, Input Código Postal, Input Código Área, Input Banco Emisor, Input Titular, Input CBU
    #input-apellido-container,
    #input-codigo-postal-container,
    #input-codigo-area-container,
    #input-modelo-container,
    #input-banco-emisor-container,
    #input-titular-container,
    #input-cbu-container,
    #input-color-container {
      margin: 30px 0px 0px 0px;
    }

    #input-apellido-container,
    #input-banco-emisor-container,
    #input-titular-container {
      text-transform: capitalize !important;
    }
  }

  // 651px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile651}) {
    // Max Width Coberturas Container
    #max-width-coberturas-container {
      max-width: 380px;
    }

    // Formulario
    form {
      max-width: 380px;
    }

    // Datos Cotización
    #datos-cotizacion {
      width: 80%;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
      align-content: flex-start;
    }

    // Input Container y Select Container, Input CUIT DNI, Input Código Área, Input Vencimiento, Input Telefono
    .input-container,
    .select-container,
    #input-cuit-dni-container,
    #input-codigo-area-container,
    #input-vencimiento-container,
    #input-telefono-container {
      width: calc(50% - 7.5px) !important;
      max-width: calc(50% - 7.5px) !important;
    }

    #datos-tarjeta-credito {
      width: 100%;
    }
  }

  // 450px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile450}) {
    // Max Width Coberturas Container
    #max-width-coberturas-container {
      max-width: 85%;
      justify-content: center;
    }

    // Logo y Plan Container
    .logo-plan-container {
      padding: 0px 0px 0px 0px;
      border-right: 0px;
    }

    // Checks Container
    .checks-container {
      display: none;
    }

    // Coberturas Container
    .coberturas-container {
      max-width: 115px;
    }

    // Formulario
    form {
      max-width: 100%;
    }

    // Input Container y Select Container, Input CUIT DNI, Input Código Área, Input Vencimiento, Input Telefono
    .input-container,
    #input-cuit-dni-container,
    #input-codigo-area-container,
    #input-vencimiento-container,
    #input-telefono-container,
    .select-container {
      width: 100% !important;
      max-width: 100% !important;
      margin: 30px 0px 0px 0px !important;
    }
  }

  // 350px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile350}) {
    // Input Container y Select Container, Input CUIT DNI, Input Código Área, Input Vencimiento, Input Telefono
    .input-container,
    #input-cuit-dni-container,
    #input-codigo-area-container,
    #input-vencimiento-container,
    #input-telefono-container {
      width: 100%;
      max-width: 100%;
      margin: 30px 0px 0px 0px;
    }
  }
`;

export const FirmaDigital = styled.div`
  margin: 30px 0 0 0;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .label-firma-digital {
    font-size: 13px;
    font-family: "DM Sans";
    color: var(--negro);
    font-weight: 500;
  }

  .limpiar-firma-digital {
    font-size: 13px;
    font-family: "DM Sans";
    color: var(--azul80);
    font-weight: 500;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 6px;

    :hover {
      opacity: 0.8;
    }
  }

  .sigCanvas {
    background-color: #fff;
    border: 1.7px solid var(--gris80);
    width: 100%;
  }

  // Formulario
  form {
    width: 100%;
    max-width: 1140px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
  }

  // 1200px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1200}) {
    #max-width-coberturas-container {
      max-width: 680px;
    }

    // Coberturas Container
    .coberturas-container {
      max-width: 290px;
      margin: 40px 0px 0px 0px;
    }

    // Checks Container
    .checks-container {
      width: 150px;
    }

    // Checks Container Span
    .checks-container span {
      display: none;
    }

    // Formulario
    form {
      max-width: 680px;
    }

    // Input Container, Select Container, Input Código Area, Input Telefono y Input Vencimiento
    .input-container,
    .select-container,
    #input-codigo-area-container,
    #input-telefono-container,
    #input-vencimiento-container {
      width: 216.5px;
      max-width: 216.5px;
    }

    // Input Código Postal
    #input-codigo-postal-container,
    #input-color-container {
      margin: 30px 15px 0px 0px;
    }

    // Input CUIT DNI, Input Telefono y Código de Área Container
    #input-cuit-dni-container,
    #input-telefono-container,
    #input-codigo-area-container,
    #input-rodado-container,
    #input-transmision-container,
    #input-vencimiento-container {
      margin: 30px 0px 0px 0px;
    }

    // Datos Tarjeta
    #datos-tarjeta-credito {
      width: 100% !important;
    }
  }

  // 850px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet850}) {
    // Max Width Coberturas Container
    #max-width-coberturas-container {
      max-width: 480px;
    }

    // Formulario
    form {
      max-width: 480px;
    }

    // Datos Vehiculo Container
    .datos-vehiculo-container {
      width: 100%;
    }

    // Input Container y Select Container, Input CUIT DNI, Input Código Área, Input Vencimiento, Input Telefono
    .input-container,
    .select-container,
    #input-cuit-dni-container,
    #input-codigo-area-container,
    #input-vencimiento-container,
    #input-telefono-container,
    #input-rodado-container {
      width: 232.5px;
      max-width: 232.5px;
      margin: 30px 15px 0px 0px;
    }

    // Input Apellido, Input Código Postal, Input Código Área, Input Banco Emisor, Input Titular, Input CBU
    #input-apellido-container,
    #input-codigo-postal-container,
    #input-codigo-area-container,
    #input-modelo-container,
    #input-banco-emisor-container,
    #input-titular-container,
    #input-cbu-container,
    #input-color-container {
      margin: 30px 0px 0px 0px;
    }

    #input-apellido-container,
    #input-banco-emisor-container,
    #input-titular-container {
      text-transform: capitalize !important;
    }
  }

  // 651px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile651}) {
    // Max Width Coberturas Container
    #max-width-coberturas-container {
      max-width: 380px;
    }

    // Formulario
    form {
      max-width: 380px;
    }

    // Datos Cotización
    #datos-cotizacion {
      width: 80%;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
      align-content: flex-start;
    }

    // Input Container y Select Container, Input CUIT DNI, Input Código Área, Input Vencimiento, Input Telefono
    .input-container,
    .select-container,
    #input-cuit-dni-container,
    #input-codigo-area-container,
    #input-vencimiento-container,
    #input-telefono-container {
      width: calc(50% - 7.5px) !important;
      max-width: calc(50% - 7.5px) !important;
    }

    #datos-tarjeta-credito {
      width: 100%;
    }
  }

  // 450px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile450}) {
    // Max Width Coberturas Container
    #max-width-coberturas-container {
      max-width: 85%;
      justify-content: center;
    }

    // Logo y Plan Container
    .logo-plan-container {
      padding: 0px 0px 0px 0px;
      border-right: 0px;
    }

    // Checks Container
    .checks-container {
      display: none;
    }

    // Coberturas Container
    .coberturas-container {
      max-width: 115px;
    }

    // Formulario
    form {
      max-width: 100%;
    }

    // Input Container y Select Container, Input CUIT DNI, Input Código Área, Input Vencimiento, Input Telefono
    .input-container,
    #input-cuit-dni-container,
    #input-codigo-area-container,
    #input-vencimiento-container,
    #input-telefono-container,
    .select-container {
      width: 100% !important;
      max-width: 100% !important;
      margin: 30px 0px 0px 0px !important;
    }
  }

  // 350px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile350}) {
    // Input Container y Select Container, Input CUIT DNI, Input Código Área, Input Vencimiento, Input Telefono
    .input-container,
    #input-cuit-dni-container,
    #input-codigo-area-container,
    #input-vencimiento-container,
    #input-telefono-container {
      width: 100%;
      max-width: 100%;
      margin: 30px 0px 0px 0px;
    }
  }
`;

export default Container;
