import valid from "card-validator";

// *** Cotizador *** //
export const cotizadorReglas = (state) => {
  return {
    nombre: [
      {
        regla: state.nombre === "",
        mensaje: "Este campo es requerido.",
      },
      {
        regla: state.nombre.length < 2,
        mensaje: "El campo nombre debe contener al menos 2 caracteres.",
      },
      {
        regla:
          !/^[a-zA-Z\u00C0-\u017F!@#$%^&':"\\|,.<>\/?\s\u00f1\u00d1]*$/.test(
            state.nombre
          ),
        mensaje: "El nombre ingresado no es válido.",
      },
    ],
    apellido: [
      {
        regla: state.apellido === "",
        mensaje: "Este campo es requerido.",
      },
      {
        regla: state.apellido.length < 2,
        mensaje: "El campo apellido debe contener al menos 2 caracteres.",
      },
      {
        regla: !/^[a-zA-Z!@#$%^&':"\\|,.<>\/?\s\u00f1\u00d1]*$/.test(
          state.apellido
        ),
        mensaje: "El apellido ingresado no es válido.",
      },
    ],
    email: [
      {
        regla: state.email === "",
        mensaje: "Este campo es requerido.",
      },
      {
        regla: !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(state.email),
        mensaje: "El email ingresado no es válido.",
      },
    ],
    provincia: [
      {
        regla: state.provincia === "",
        mensaje: "Este campo es requerido.",
      },
    ],
    mascota: [
      {
        regla: state.mascota === "",
        mensaje: "Este campo es requerido.",
      },
    ],
    raza: [
      {
        regla: state.raza === "" && !state.mestizo,
        mensaje: "Este campo es requerido.",
      },
    ],
    edad: [
      {
        regla: state.edad === "",
        mensaje: "Este campo es requerido.",
      },
    ],
    telefono: [
      {
        regla: state.telefono === "",
        mensaje: "Este campo es requerido.",
      },
      {
        regla: state.telefono.length < 8,
        mensaje: "El campo telefono es menor a 8 digitos.",
      },
    ],
    codigoArea: [
      {
        regla: state.codigoArea === "",
        mensaje: "Este campo es requerido.",
      },
      {
        regla: state.codigoArea.length < 2 || state.codigoArea.length > 5,
        mensaje: "El código de área debe tener más de 2 digitos y menos de 5.",
      },
    ],
  };
};

// *** Referidos *** //
export const referidosReglas = (state) => {
  return {
    nombre: [
      {
        regla: state.nombre === "",
        mensaje: 'El campo "Nombre" en "Datos del referido" es requerido.',
      },
      {
        regla: state.nombre.length < 2,
        mensaje:
          "El campo nombre del referido debe contener al menos 2 caracteres.",
      },
      {
        regla:
          !/^[a-zA-Z\u00C0-\u017F!@#$%^&':"\\|,.<>\/?\s\u00f1\u00d1]*$/.test(
            state.nombre
          ),
        mensaje: "El nombre del referido ingresado no es válido.",
      },
    ],
    email: [
      {
        regla: state.email === "",
        mensaje: 'El campo "Email" en "Datos personales" es requerido.',
      },
      {
        regla: !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(state.email),
        mensaje: "El email ingresado no es válido.",
      },
    ],
  };
};

// *** Arrepentimiento *** //
export const arrepentimientoReglas = (state) => {
  return {
    nombre: [
      {
        regla: state.nombre === "",
        mensaje: "El campo nombre es requerido.",
      },
      {
        regla: state.nombre.length < 2,
        mensaje: "El campo nombre debe contener al menos 2 caracteres.",
      },
      {
        regla:
          !/^[a-zA-Z\u00C0-\u017F!@#$%^&':"\\|,.<>\/?\s\u00f1\u00d1]*$/.test(
            state.nombre
          ),
        mensaje: "El nombre ingresado no es válido.",
      },
    ],
    apellido: [
      {
        regla: state.apellido === "",
        mensaje: "El campo apellido es requerido.",
      },
      {
        regla: state.apellido.length < 2,
        mensaje: "El campo apellido debe contener al menos 2 caracteres.",
      },
      {
        regla: !/^[a-zA-Z!@#$%^&':"\\|,.<>\/?\s\u00f1\u00d1]*$/.test(
          state.apellido
        ),
        mensaje: "El apellido ingresado no es válido.",
      },
    ],
    email: [
      {
        regla: state.email === "",
        mensaje: "El campo apellido es requerido.",
      },
      {
        regla: !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(state.email),
        mensaje: "El email ingresado no es válido.",
      },
    ],
    dni: [
      {
        regla: state.dni === "",
        mensaje: "El campo DNI es requerido.",
      },
      {
        regla: state.dni.length !== 8,
        mensaje: "El campo DNI es requerido.",
      },
    ],
    telefono: [
      {
        regla: state.telefono === "",
        mensaje: "El campo teléfono es requerido.",
      },
      {
        regla: state.telefono.length < 8,
        mensaje: "El campo telefono es menor a 8 digitos.",
      },
    ],
    mensaje: [
      {
        regla: state.mensaje === "",
        mensaje: "Tu comentario es requerido.",
      },
    ],
  };
};

// *** Baja Servicio *** //
export const bajaServicioReglas = (state) => {
  return {
    nombre: [
      {
        regla: state.nombre === "",
        mensaje: "El campo nombre es requerido.",
      },
      {
        regla: state.nombre.length < 2,
        mensaje: "El campo nombre debe contener al menos 2 caracteres.",
      },
      {
        regla:
          !/^[a-zA-Z\u00C0-\u017F!@#$%^&':"\\|,.<>\/?\s\u00f1\u00d1]*$/.test(
            state.nombre
          ),
        mensaje: "El nombre ingresado no es válido.",
      },
    ],
    apellido: [
      {
        regla: state.apellido === "",
        mensaje: "El campo apellido es requerido.",
      },
      {
        regla: state.apellido.length < 2,
        mensaje: "El campo apellido debe contener al menos 2 caracteres.",
      },
      {
        regla: !/^[a-zA-Z!@#$%^&':"\\|,.<>\/?\s\u00f1\u00d1]*$/.test(
          state.apellido
        ),
        mensaje: "El apellido ingresado no es válido.",
      },
    ],
    email: [
      {
        regla: state.email === "",
        mensaje: "El campo apellido es requerido.",
      },
      {
        regla: !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(state.email),
        mensaje: "El email ingresado no es válido.",
      },
    ],
    dni: [
      {
        regla: state.dni === "",
        mensaje: "El campo DNI es requerido.",
      },
    ],
    cuil: [
      {
        regla: state.cuil === "",
        mensaje: "El campo DNI es requerido.",
      },
    ],
    telefono: [
      {
        regla: state.telefono === "",
        mensaje: "El campo teléfono es requerido.",
      },
      {
        regla: state.telefono.length < 8,
        mensaje: "El campo telefono es menor a 8 digitos.",
      },
    ],
    poliza: [
      {
        regla: state.poliza === "",
        mensaje: "El campo Póliza es requerido.",
      },
    ],
    aseguradora: [
      {
        regla: state.aseguradora === "",
        mensaje: "El campo Asegurado es requerido.",
      },
    ],
    producto: [
      {
        regla: state.producto === "",
        mensaje: "El campo Bien Asegurado es requerido.",
      },
    ],
    motivo: [
      {
        regla: state.motivo === "",
        mensaje: "El motivo de anulación es requerido.",
      },
    ],
    motivo_anulacion_otros: [
      {
        regla: state.motivo === "5" && state.motivo_anulacion_otros === "",
        mensaje: "El motivo de la anulación es requerido.",
      },
    ],
  };
};

// *** Contacto *** //
export const contactoReglas = (state) => {
  return {
    nombre: [
      {
        regla: state.nombre === "",
        mensaje: "El campo nombre es requerido.",
      },
      {
        regla: state.nombre.length < 2,
        mensaje: "El campo nombre debe contener al menos 2 caracteres.",
      },
      {
        regla:
          !/^[a-zA-Z\u00C0-\u017F!@#$%^&':"\\|,.<>\/?\s\u00f1\u00d1]*$/.test(
            state.nombre
          ),
        mensaje: "El nombre ingresado no es válido.",
      },
    ],
    apellido: [
      {
        regla: state.apellido === "",
        mensaje: "El campo apellido es requerido.",
      },
      {
        regla: state.apellido?.length < 2,
        mensaje: "El campo apellido debe contener al menos 2 caracteres.",
      },
      {
        regla: !/^[a-zA-Z!@#$%^&':"\\|,.<>\/?\s\u00f1\u00d1]*$/.test(
          state.apellido
        ),
        mensaje: "El apellido ingresado no es válido.",
      },
    ],
    email: [
      {
        regla: state.email === "",
        mensaje: "El campo apellido es requerido.",
      },
      {
        regla: !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(state.email),
        mensaje: "El email ingresado no es válido.",
      },
    ],
    telefono: [
      {
        regla: state.telefono === "",
        mensaje: "El campo teléfono es requerido.",
      },
      {
        regla: state.telefono.length < 8,
        mensaje: "El campo telefono es menor a 8 digitos.",
      },
    ],
    mensaje: [
      {
        regla: state.mensaje === "",
        mensaje: "Tu comentario es requerido.",
      },
    ],
  };
};

// *** Forma Parte *** //
export const formaParteReglas = (state) => {
  return {
    nombre: [
      {
        regla: state.nombre === "",
        mensaje: "El campo nombre es requerido.",
      },
      {
        regla: state.nombre.length < 2,
        mensaje: "El campo nombre debe contener al menos 2 caracteres.",
      },
      {
        regla:
          !/^[a-zA-Z\u00C0-\u017F!@#$%^&':"\\|,.<>\/?\s\u00f1\u00d1]*$/.test(
            state.nombre
          ),
        mensaje: "El nombre ingresado no es válido.",
      },
    ],
    apellido: [
      {
        regla: state.apellido === "",
        mensaje: "Este campo es requerido.",
      },
      {
        regla: state.apellido.length < 2,
        mensaje: "El campo apellido debe contener al menos 2 caracteres.",
      },
      {
        regla: !/^[a-zA-Z!@#$%^&':"\\|,.<>\/?\s\u00f1\u00d1]*$/.test(
          state.apellido
        ),
        mensaje: "El apellido ingresado no es válido.",
      },
    ],
    email: [
      {
        regla: state.email === "",
        mensaje: "El campo apellido es requerido.",
      },
      {
        regla: !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(state.email),
        mensaje: "El email ingresado no es válido.",
      },
    ],
    puesto: [
      {
        regla: state.puesto === "",
        mensaje: "El campo puesto es requerido.",
      },
    ],
    linkedin: [
      {
        regla:
          state.linkedin &&
          !state.linkedin.includes("https") &&
          !state.linkedin.includes("linkedin"),
        mensaje: "El link no es válido.",
      },
    ],
    mensaje: [
      {
        regla: state.mensaje === "",
        mensaje: "Tu comentario es requerido.",
      },
    ],
    cv: [
      {
        regla: state.cv === "",
        mensaje: "Tu CV es requerido.",
      },
    ],
  };
};

// *** Forma Parte *** //
export const contrataReglas = (state) => {
  const notValidCBUs = ["0143", "0147", "0158", "0165", "0384", "000000"];

  return {
    nombre: [
      {
        regla: state.nombre === "",
        mensaje: "Este campo es requerido.",
      },
      {
        regla: state.nombre.length < 2,
        mensaje: "El campo nombre debe contener al menos 2 caracteres.",
      },
      {
        regla:
          !/^[a-zA-Z\u00C0-\u017F!@#$%^&':"\\|,.<>\/?\s\u00f1\u00d1]*$/.test(
            state.nombre
          ),
        mensaje: "El nombre ingresado no es válido.",
      },
    ],
    apellido: [
      {
        regla: state.apellido === "",
        mensaje: "Este campo es requerido.",
      },
      {
        regla: state.apellido.length < 2,
        mensaje: "El campo apellido debe contener al menos 2 caracteres.",
      },
      {
        regla: !/^[a-zA-Z!@#$%^&':"\\|,.<>\/?\s\u00f1\u00d1]*$/.test(
          state.apellido
        ),
        mensaje: "El apellido ingresado no es válido.",
      },
    ],
    email: [
      {
        regla: state.email === "",
        mensaje: "Este campo es requerido.",
      },
      {
        regla: !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(state.email),
        mensaje: "El email ingresado no es válido.",
      },
    ],
    telefono: [
      {
        regla: state.telefono === "",
        mensaje: "Este campo es requerido.",
      },
      {
        regla: state.telefono.length < 8,
        mensaje: "El campo telefono es menor a 8 digitos.",
      },
    ],
    codigoArea: [
      {
        regla: state.codigoArea === "",
        mensaje: "Este campo es requerido.",
      },
      {
        regla: state.codigoArea.length < 2 || state.codigoArea.length > 5,
        mensaje: "El código de área debe tener más de 2 digitos y menos de 5.",
      },
    ],
    codigoPostal: [
      {
        regla: state.codigoPostal === "",
        mensaje: "Este campo es requerido.",
      },
    ],
    cuitDNI: [
      {
        regla: state.cuitDNI === "",
        mensaje: "Este campo es requerido.",
      },
      {
        regla: state.cuitDNI.length < 8 || state.cuitDNI.length > 11,
        mensaje: "El CUIT/DNI es inválido.",
      },
    ],
    formaDePago: [
      {
        regla: state.formaDePago === "",
        mensaje: "Este campo es requerido.",
      },
    ],
    titular: [
      {
        regla:
          state.titular === "" && state.formaDePago === "Tarjeta de credito",
        mensaje: "Este campo es requerido.",
      },
      {
        regla:
          !valid.cardholderName(state.titular).isValid &&
          state.formaDePago === "Tarjeta de credito",
        mensaje: "El nombre ingresado no es válido.",
      },
      {
        regla:
          state.titular.length < 2 &&
          state.formaDePago === "Tarjeta de credito",
        mensaje:
          "El campo Titular de la tarjeta debe contener al menos 2 caracteres",
      },
      {
        regla:
          !/^[a-zA-Z\u00C0-\u017F!@#$%^&':"\\|,.<>\/?\s\u00f1\u00d1]*$/.test(
            state.titular
          ) && state.formaDePago === "Tarjeta de credito",
        mensaje: "El nombre ingresado no es válido.",
      },
    ],
    numeroTarjeta: [
      {
        regla:
          state.numeroTarjeta === "" &&
          state.formaDePago === "Tarjeta de credito",
        mensaje: "Este campo es requerido.",
      },
      {
        regla:
          (state.numeroTarjeta.startsWith("4517") ||
            state.numeroTarjeta.startsWith("4815") ||
            state.numeroTarjeta.startsWith("5285")) &&
          state.formaDePago === "Tarjeta de credito",
        mensaje:
          "El número de tarjeta ingresado corresponde a una tarjeta de debito.",
      },
      {
        regla:
          !valid.number(state.numeroTarjeta).isValid &&
          state.formaDePago === "Tarjeta de credito",
        mensaje: "El número de tarjeta ingresado no es válido.",
      },
      {
        regla:
          !valid.number(state.numeroTarjeta).isValid &&
          state.formaDePago === "Tarjeta de credito",
        mensaje: "El número de tarjeta ingresado no es válido.",
      },
    ],
    vencimiento: [
      {
        regla:
          state.vencimiento === "" &&
          state.formaDePago === "Tarjeta de credito",
        mensaje: "Este campo es requerido.",
      },
      {
        regla:
          !valid.expirationDate(state.vencimiento).isValid &&
          state.formaDePago === "Tarjeta de credito",
        mensaje: "La fecha de vencimiento ingresada no es válida.",
      },
    ],
    dniTitular: [
      {
        regla:
          state.dniTitular === "" && state.formaDePago === "Tarjeta de credito",
        mensaje: "Este campo es requerido.",
      },
      {
        regla:
          state.dniTitular.length !== 8 &&
          state.formaDePago === "Tarjeta de credito",
        mensaje: "El campo DNI solo permite 8 digitos.",
      },
    ],
    bancoEmisor: [
      {
        regla:
          state.bancoEmisor === "" &&
          state.formaDePago === "Tarjeta de credito",
        mensaje: "Este campo es requerido.",
      },
      {
        regla:
          !/^[A-Za-z'\s\u00C0-\u017F]+$/.test(state.bancoEmisor) &&
          state.formaDePago === "Tarjeta de credito",
        mensaje: "El Banco emisor ingresado no es válido.",
      },
    ],
    cbu: [
      {
        regla:
          state.cbu === "" && state.formaDePago === "Transferencia bancaria",
        mensaje: "Este campo es requerido.",
      },
      {
        regla:
          state.cbu.length !== 22 &&
          state.formaDePago === "Transferencia bancaria",
        mensaje: `El CBU ingresado debe contener 22 digitos. El valor ingresado tiene ${state.cbu.length} digitos.`,
      },
      {
        regla:
          notValidCBUs.some((cbuNumber) => state.cbu.startsWith(cbuNumber)) &&
          state.formaDePago === "Transferencia bancaria",
        mensaje: `CBU no bancarizado.`,
      },
    ],
  };
};

// *** Otros Seguros *** //
export const otrosSegurosReglas = (state) => {
  return {
    nombre: [
      {
        regla: state.nombre === "",
        mensaje: "El campo nombre es requerido.",
      },
      {
        regla: state.nombre.length < 2,
        mensaje: "El campo nombre debe contener al menos 2 caracteres.",
      },
      {
        regla:
          !/^[a-zA-Z\u00C0-\u017F!@#$%^&':"\\|,.<>\/?\s\u00f1\u00d1]*$/.test(
            state.nombre
          ),
        mensaje: "El nombre ingresado no es válido.",
      },
    ],
    apellido: [
      {
        regla: state.apellido === "",
        mensaje: "Este campo es requerido.",
      },
      {
        regla: state.apellido.length < 2,
        mensaje: "El campo apellido debe contener al menos 2 caracteres.",
      },
      {
        regla: !/^[a-zA-Z!@#$%^&':"\\|,.<>\/?\s\u00f1\u00d1]*$/.test(
          state.apellido
        ),
        mensaje: "El apellido ingresado no es válido.",
      },
    ],
    email: [
      {
        regla: state.email === "",
        mensaje: "El campo apellido es requerido.",
      },
      {
        regla: !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(state.email),
        mensaje: "El email ingresado no es válido.",
      },
    ],
    provincia: [
      {
        regla: state.provincia === "",
        mensaje: "El campo provincia es requerido.",
      },
    ],
    categoria: [
      {
        regla: state.categoria === "",
        mensaje: "El campo categoria es requerido.",
      },
    ],
  };
};

// *** Siniestros Pólizas *** //
export const validarSiniestroReglas = (state) => {
  return {
    dni: [
      {
        regla: state.dni === "",
        mensaje: "El DNI es requerido.",
      },
    ],
  };
};

// *** Crear Siniestro *** //
export const crearSiniestrosReglas = (state) => {
  const maxFileSizeInMB = 2;
  const maxFileSizeInKB = 1024 * 1024 * maxFileSizeInMB;

  let checkFiles = [];
  if (state.archivo_extras.length > 0) {
    state.archivo_extras.forEach((archivo) => {
      if (archivo?.size > maxFileSizeInKB) {
        checkFiles.push(archivo.name);
      }
    });
  }

  return {
    email: [
      {
        regla: state.email === "",
        mensaje: "Este campo es requerido.",
      },
      {
        regla: !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(state.email),
        mensaje: "El email ingresado no es válido.",
      },
    ],
    telefono: [
      {
        regla: state.telefono === "",
        mensaje: "Este campo es requerido.",
      },
      {
        regla: state.telefono.length < 8,
        mensaje: "El campo teléfono es menor a 8 digitos.",
      },
    ],
    codigo_area: [
      {
        regla: state.codigo_area === "",
        mensaje: "Este campo es requerido.",
      },
      {
        regla: state.codigo_area.length < 2 || state.codigo_area.length > 5,
        mensaje: "El código de área debe tener más de 2 digitos y menos de 5.",
      },
    ],
    producto: [
      {
        regla: state.producto === "",
        mensaje: "El campo es requerido.",
      },
    ],
    tipo: [
      {
        regla: state.tipo === "",
        mensaje: "El campo es requerido.",
      },
    ],
    provincia: [
      {
        regla: state.provincia === "",
        mensaje: "El campo es requerido.",
      },
    ],
    localidad: [
      {
        regla: state.localidad === "",
        mensaje: "El campo es requerido.",
      },
    ],
    fecha: [
      {
        regla: state.fecha === "",
        mensaje: "El campo es requerido.",
      },
    ],
    hora: [
      {
        regla: state.hora === "",
        mensaje: "El campo es requerido.",
      },
    ],
    calle: [
      {
        regla: state.calle === "",
        mensaje: "El campo es requerido.",
      },
    ],
    altura: [
      {
        regla: state.altura === "",
        mensaje: "El campo es requerido.",
      },
    ],
    descripcion: [
      {
        regla: state.descripcion === "",
        mensaje: "El campo es requerido.",
      },
    ],
    archivo_foto_dni: [
      {
        regla: state.archivo_foto_dni?.size > maxFileSizeInKB,
        mensaje: "El archivo cargado excede el límite de 2mb.",
      },
    ],
    archivo_denuncia_policial: [
      {
        regla: state.archivo_denuncia_policial?.size > maxFileSizeInKB,
        mensaje: "El archivo cargado excede el límite de 2mb.",
      },
    ],
    archivo_foto_bien_siniestrado: [
      {
        regla: state.archivo_foto_bien_siniestrado?.size > maxFileSizeInKB,
        mensaje: "El archivo cargado excede el límite de 2mb.",
      },
    ],
    archivo_extras: [
      {
        regla: state.archivo_extras.length && state.archivo_extras.length > 5,
        mensaje: "Solo se permiten hasta 5 archivos.",
      },
      {
        regla: checkFiles.length > 0,
        mensaje: `Los siguientes archivos pesan más de 2mb: ${checkFiles.join(
          ", "
        )}`,
      },
    ],
  };
};

const validacionesProductoAnulacionCalidad = (state, campos, producto) => {
  const validaciones = {
    // BICICLETAS
    1: {
      marca: [
        {
          regla: state.marca === "",
          mensaje: "Este campo es requerido.",
        },
      ],
      modelo: [
        {
          regla: state.modelo === "",
          mensaje: "Este campo es requerido.",
        },
      ],
    },
    // BICICLETAS ELECTRICAS
    44: {
      marca: [
        {
          regla: state.marca === "",
          mensaje: "Este campo es requerido.",
        },
      ],
      modelo: [
        {
          regla: state.modelo === "",
          mensaje: "Este campo es requerido.",
        },
      ]
    },
    // MONOPATIN
    45: {
      marca: [
        {
          regla: state.marca === "",
          mensaje: "Este campo es requerido.",
        },
      ],
      modelo: [
        {
          regla: state.modelo === "",
          mensaje: "Este campo es requerido.",
        },
      ]
    },
    // NOTEBOOKS
    10: {
      marca: [
        {
          regla: state.marca === "",
          mensaje: "Este campo es requerido.",
        },
      ],
      modelo: [
        {
          regla: state.modelo === "",
          mensaje: "Este campo es requerido.",
        },
      ],
      num_serie: [
        {
          regla: state.num_serie === "",
          mensaje: "Este campo es requerido.",
        },
      ],
    },
    // TABLET
    11: {
      marca: [
        {
          regla: state.marca === "",
          mensaje: "Este campo es requerido.",
        },
      ],
      modelo: [
        {
          regla: state.modelo === "",
          mensaje: "Este campo es requerido.",
        },
      ],
      num_serie: [
        {
          regla: state.num_serie === "",
          mensaje: "Este campo es requerido.",
        },
      ],
    },
    // CELULARES
    4: {
      marca: [
        {
          regla: state.marca === "",
          mensaje: "Este campo es requerido.",
        },
      ],
      modelo: [
        {
          regla: state.modelo === "",
          mensaje: "Este campo es requerido.",
        },
      ],
      imei: [
        {
          regla: state.imei === "",
          mensaje: "Este campo es requerido.",
        },
      ],
    },
    // HOGAR
    2: {
      direccion: [
        {
          regla: state.direccion === "",
          mensaje: "Este campo es requerido.",
        },
      ],
    },
    // AUTOS
    3: {
      marca: [
        {
          regla: state.marca === "",
          mensaje: "Este campo es requerido.",
        },
      ],
      modelo: [
        {
          regla: state.modelo === "",
          mensaje: "Este campo es requerido.",
        },
      ],
      numero_dominio: [
        {
          regla: state.numero_dominio === "",
          mensaje: "Este campo es requerido.",
        },
      ],
      numero_chasis: [
        {
          regla: state.numero_chasis === "",
          mensaje: "Este campo es requerido.",
        },
      ],
    },
    // MOTOS
    5: {
      marca: [
        {
          regla: state.marca === "",
          mensaje: "Este campo es requerido.",
        },
      ],
      modelo: [
        {
          regla: state.modelo === "",
          mensaje: "Este campo es requerido.",
        },
      ],
      numero_dominio: [
        {
          regla: state.numero_dominio === "",
          mensaje: "Este campo es requerido.",
        },
      ],
      numero_chasis: [
        {
          regla: state.numero_chasis === "",
          mensaje: "Este campo es requerido.",
        },
      ],
    },
    // CAMIONES
    13: {
      marca: [
        {
          regla: state.marca === "",
          mensaje: "Este campo es requerido.",
        },
      ],
      modelo: [
        {
          regla: state.modelo === "",
          mensaje: "Este campo es requerido.",
        },
      ],
      numero_dominio: [
        {
          regla: state.numero_dominio === "",
          mensaje: "Este campo es requerido.",
        },
      ],
      numero_chasis: [
        {
          regla: state.numero_chasis === "",
          mensaje: "Este campo es requerido.",
        },
      ],
    },
    // CAMIONES
    14: {
      marca: [
        {
          regla: state.marca === "",
          mensaje: "Este campo es requerido.",
        },
      ],
      modelo: [
        {
          regla: state.modelo === "",
          mensaje: "Este campo es requerido.",
        },
      ],
      numero_chasis: [
        {
          regla: state.numero_chasis === "",
          mensaje: "Este campo es requerido.",
        },
      ],
    },
    // SCOOTER
    15: {
      marca: [
        {
          regla: state.marca === "",
          mensaje: "Este campo es requerido.",
        },
      ],
      modelo: [
        {
          regla: state.modelo === "",
          mensaje: "Este campo es requerido.",
        },
      ],
      numero_dominio: [
        {
          regla: state.numero_dominio === "",
          mensaje: "Este campo es requerido.",
        },
      ],
      numero_chasis: [
        {
          regla: state.numero_chasis === "",
          mensaje: "Este campo es requerido.",
        },
      ],
    },
    // CICLOMOTOR
    16: {
      marca: [
        {
          regla: state.marca === "",
          mensaje: "Este campo es requerido.",
        },
      ],
      modelo: [
        {
          regla: state.modelo === "",
          mensaje: "Este campo es requerido.",
        },
      ],
      numero_dominio: [
        {
          regla: state.numero_dominio === "",
          mensaje: "Este campo es requerido.",
        },
      ],
      numero_chasis: [
        {
          regla: state.numero_chasis === "",
          mensaje: "Este campo es requerido.",
        },
      ],
    },
    // ACCIDENTES PERSONALES
    20: {
      archivo_frente_dni: [
        {
          regla: state.archivo_frente_dni === "",
          mensaje: "Este campo es requerido.",
        },
      ],
    },
    // SALUD
    53: {
      archivo_frente_dni: [
        {
          regla: state.archivo_frente_dni === "",
          mensaje: "Este campo es requerido.",
        },
      ],
    },
    // HOGAR
    60: {
      direccion: [
        {
          regla: state.direccion === "",
          mensaje: "Este campo es requerido.",
        },
      ],
    },
    // SEGURO TECNICO
    31: {
      marca: [
        {
          regla: state.marca === "",
          mensaje: "Este campo es requerido.",
        },
      ],
      modelo: [
        {
          regla: state.modelo === "",
          mensaje: "Este campo es requerido.",
        },
      ],
    },
    // CASCOS
    63: {
      marca: [
        {
          regla: state.marca === "",
          mensaje: "Este campo es requerido.",
        },
      ],
      modelo: [
        {
          regla: state.modelo === "",
          mensaje: "Este campo es requerido.",
        },
      ],
    },
  };
  let validacionesProducto = {};
  for (const key in validaciones[producto]) {
    const checkCampo = campos.find((campo) => key === campo.name_campo);
    if (checkCampo) validacionesProducto[key] = validaciones[producto][key];
  }
  return validacionesProducto;
};

export const anulacionCalidadProductoReglas = (state, wizardData) => {
  const { campos, producto } = wizardData;
  const validaciones = validacionesProductoAnulacionCalidad(
    state,
    campos,
    producto
  );
  return validaciones;
};
