// Estilos
import Container from "./styled";
// Librerías
import { HiOutlineArrowLongLeft } from "react-icons/hi2";
// Componentes
import Aside from "../aside";
import BajaForm from "../../Forms/BajaForm";
import useBaja from "./hooks/useBaja";

export default function Formulario({ pasoSiguiente }) {
  
  // Custom hook
  const { formBaja, formProducto, data, firmaDigitalRef } = useBaja(pasoSiguiente)
  const { productos, aseguradoras, loading, camposProducto, motivos } = data
  const { inputValues, handleChange, handleValidation, formErrors } = formBaja

  return (
    <Container>
      {/* Max Width Container */}
      <div id="max-width-container">
        {/* Formulario Container */}
        <div id="formulario-container">
          <div id="titulo-container">
            {/* Botón Inicio */}
            <a id="boton-inicio" href="/">
              <span>
                <HiOutlineArrowLongLeft />
              </span>{" "}
              Inicio
            </a>
            {/* Título */}
            <h3>
              ¿Querés <span>dar de baja</span> tu póliza?
            </h3>
            {/* Tag */}
            <p id="tag">Resolución 424/2020</p>
            {/* Texto */}
            <p id="texto">
              La resolución 424/2020 de la Secretaría de Comercio Interior,
              otorgá el derecho de que puedas dar de baja tu póliza en el
              momento que vos quieras.
            </p>
            <p id="texto">
              Si buscas dar de baja tu póliza de SeguroWeb, te pedimos que
              completes el siguiente formulario con tus datos. Dentro de las
              próximas 24hs hábiles nos vamos a comunicar con vos para otorgarte
              tu número de solicitud generado y avanzar con el proceso.
            </p>
          </div>
          {/* Formulario */}
          <BajaForm formProducto={formProducto} formValues={{ inputValues, handleChange, handleValidation, formErrors, productos, aseguradoras, loading, camposProducto, motivos }} firmaDigitalRef={firmaDigitalRef} />
        </div>
        {/* Separación */}
        <div id="separacion">
          <hr></hr>
        </div>
        <Aside></Aside>
      </div>
    </Container>
  );
}
