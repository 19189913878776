import { useEffect, useState } from "react";
import useValidation from "./useValidation";

export const useForm = (submitCallback, rules, initialValues, wizardData) => {

  const [inputValues, setInputValues] = useState(initialValues);
  const { validateForm, formErrors, resetFormErrors } = useValidation();

  useEffect(() => {
      setInputValues(initialValues)
  }, [initialValues])

  const completeForm = (values) => {
    setInputValues(values)
  }

  const completeField = (name, value) => {
    setInputValues((prev) => ({ ...prev, [name]: value }))
  }

  const resetForm = (state) => { 
    setInputValues(state ? state : initialValues)
  }

  const resetFieldsValues = (inputName) => {
    const keys = Object.keys(inputValues)
    const currentInputIndex = keys.findIndex(key => key === inputName)
    const keysAfterCurrentInput = keys.slice(currentInputIndex + 1, keys.length)
    keysAfterCurrentInput.forEach(key => {
      setInputValues((prev) => ({ ...prev, [key]: '' }))
    })
  }

  const handleChange = (e, resetFields) => {
    const { name, value } = e.target
    setInputValues((prev) => ({ ...prev, [name]: value }))
    if(resetFields){
      resetFieldsValues(name)
    }
  };

  // Para elementos del form que no poseen atributo name (captcha)
  const handleChangeExternal = (fieldName, fieldValue) => {
    setInputValues((prev) => ({ ...prev, [fieldName]: fieldValue }));
  }

  const handleChangeCheckbox = (e) => {
    const { name, checked } = e.target
    setInputValues((prev) => ({ ...prev, [name]: checked }))
  }
  
  // Validar Formulario
  const handleValidation = (e) => {
    e.preventDefault();
    const validationRules = rules(inputValues, wizardData)
    console.log(validationRules)
    const formValidation = validateForm(validationRules)
    console.log(formValidation)
    if (Object.keys(formValidation).length === 0 && submitCallback !== null) {
      submitCallback()
    } else {
      if(Object.keys(formValidation).length === 0){
        return true
      } else {
        return false
      }

    }
  };

  // Validar Formulario Async
  const handleValidationAsync = async (e) => {
    e.preventDefault();
    const validationRules = await rules(inputValues, wizardData)
    const formValidation = validateForm(validationRules)
    if (Object.keys(formValidation).length === 0 && submitCallback !== null){
      submitCallback()
    } else {  
      if(Object.keys(formValidation).length === 0){
        return true
      } else {
        return false
      }

    }
  };

  return {
    inputValues,
    handleChange,
    handleValidation,
    handleChangeCheckbox,
    formErrors,
    handleChangeExternal,
    handleValidationAsync,
    resetForm,
    completeForm,
    resetFormErrors,
    completeField
  };
};

// import { useState } from "react";
// import useValidation from "./useValidation";
// import { fieldsExcludedFromStorage } from "../constants/form";

// export const useForm = (submitCallback, rules, initialValues, wizardData) => {
//   const [inputValues, setInputValues] = useState(initialValues);
//   const { validateForm, formErrors } = useValidation();

//   const resetFieldsValues = (inputName) => {
//     const keys = Object.keys(inputValues)
//     const currentInputIndex = keys.findIndex(key => key === inputName)
//     const keysAfterCurrentInput = keys.slice(currentInputIndex + 1, keys.length)
//     keysAfterCurrentInput.forEach(key => {
//       setInputValues((prev) => ({ ...prev, [key]: '' }))
//       localStorage.setItem(`${key}-seguromascotas`, "");
//     })
//   }

//   const handleChange = (e, resetFields) => {
//     const { name, value } = e.target
//     setInputValues((prev) => ({ ...prev, [name]: value }))
//     if(!fieldsExcludedFromStorage.includes(name)){
//       localStorage.setItem([name] + "-seguromascotas", value)
//     }
//     if(resetFields){
//       resetFieldsValues(name)
//     }
//   };

//   // Para elementos del form que no poseen atributo name (captcha)
//   const handleChangeExternal = (fieldName, fieldValue) => {
//     setInputValues((prev) => ({ ...prev, [fieldName]: fieldValue }));
//   }

//   const handleChangeCheckbox = (e) => {
//     const { name, checked } = e.target
//     setInputValues((prev) => ({ ...prev, [name]: checked }))
//   }

//   const handleValidation = (e) => {
//     e.preventDefault();
//     const validationRules = rules(inputValues, wizardData)
//     const formValidation = validateForm(validationRules)
//     console.log(formValidation)
//     if (Object.keys(formValidation).length === 0 && submitCallback !== null) {
//       //submitCallback()
//     } else {
//       if(Object.keys(formValidation).length === 0){
//         return true
//       } else {
//         return false
//       }

//     }
//   };

//   return {
//     inputValues,
//     handleChange,
//     handleValidation,
//     handleChangeCheckbox,
//     formErrors,
//     handleChangeExternal
//   };
// };
