// Hooks de React
import { useEffect, useRef, useState } from "react";
// Custom Hooks
import { useForm } from "../../../../hooks/useForm";
import { useLoading } from "../../../../hooks/useLoading";
import { useFile } from "../../../../hooks/useFile";
// Contexts
import { useSocket } from "../../../../context/SocketProvider";
// Utils
import url from "../../../../utils/url";
import { bajaServicioForm } from "../../../../utils/formInitialValues";
import {
  anulacionCalidadProductoReglas,
  bajaServicioReglas,
} from "../../../../utils/formRules";
import { bajaMail } from "../../../../utils/mailContent";
import { sendFormMail } from "../../../../utils/sendFormMail";
import { goToTop } from "../../../../utils/scrollToTop";
// Servicios
import {
  getCamposProductoAnulacion,
  getMotivosBaja,
  obtenerProductosCalidad,
  postearAnulacion,
} from "../../../../services/baja";
import { obtenerAseguradoras } from "../../../../services/aseguradoras";
// Librerias externas
import axios from "axios";

export default function useBaja(pasoSiguiente) {
  // Context socket para enviar notificaciones
  const socket = useSocket();

  // Custom Hook para formulario de baja
  const { inputValues, handleChange, handleValidation, formErrors } = useForm(
    handleSubmit,
    bajaServicioReglas,
    bajaServicioForm
  );
  const { loading, initiateLoading } = useLoading();

  // Estados
  const [productos, setProductos] = useState([]);
  const [aseguradoras, setAseguradoras] = useState([]);
  const [motivos, setMotivos] = useState([]);
  const [camposProducto, setCamposProducto] = useState([]);
  const [productoFormInitialValues, setProductoFormInitialValues] = useState(
    {}
  );

  // Custom hook para formulario de producto (los campos dependen del producto seleccionado en el formulario de baja)
  const formProducto = useForm(
    null,
    anulacionCalidadProductoReglas,
    productoFormInitialValues,
    { campos: camposProducto, producto: inputValues.producto }
  );

  // Ref para obtener valor del campo de firma digital
  const firmaDigitalRef = useRef();

  // Custom hook para subida de archivos
  const { sendFiles } = useFile(formProducto.handleChangeExternal);

  // Guardar información en la base de datos, enviar notificación al CRM y enviar mail con los datos de la baja
  async function handleSubmit() {
    try {
      initiateLoading();
      const bajaFormData = { ...inputValues };
      const productoData = { ...formProducto.inputValues };
      const URL = firmaDigitalRef.current
        ? firmaDigitalRef.current.getTrimmedCanvas().toDataURL("image/png")
        : null;

      // Transformar base64 a blob para poder subir la imagen de la firma digital a la API Storage
      if (URL) {
        const firmaDigitalBase64 = await fetch(URL);
        const firmaDigitalBlob = await firmaDigitalBase64.blob();
        bajaFormData["archivo_firma_digital"] = {
          name: `firma_digital.png`,
          image: firmaDigitalBlob,
        };
      }

      // Subir imagen de firma digital a la API Storage
      const filePath = await sendFiles(bajaFormData["archivo_firma_digital"]);
      bajaFormData["archivo_firma_digital"] = filePath[0].path;

      // Filtrar archivos dentro de los datos del formulario para subirlos a la API Storage
      const filtrarArchivos = Object.entries(productoData).filter(
        ([key, value]) => key.includes("archivo") && value !== ""
      );
      if (filtrarArchivos.length > 0) {
        await Promise.all(
          filtrarArchivos.map(async ([key, value]) => {
            const filePath = await sendFiles(value);
            productoData[key] = filePath[0].path;
          })
        );
      }

      // Guardar datos de la baja en la base de datos
      const anulacion = await postearAnulacion({
        anulacion: bajaFormData,
        productoData: productoData,
        campos: camposProducto,
      });

      // Crear notificación para el CRM
      const notificacion = {
        url_pagina: `/ControlCalidad/Anulacion/${anulacion.data.id}`,
        nombre_icono: "calidad",
        nombre_pagina: "Control Calidad",
        notificacion: `Nueva anulación de póliza cargada desde Seguro Web: Póliza ${anulacion.data.poliza} | DNI ${anulacion.data.dni} | Asegurado ${anulacion.data.nombre} ${anulacion.data.apellido}`,
        dia: new Date(),
        tipo: "calidad",
        operador: anulacion.data.id_operador,
      };
      // Emitir evento de notifcación al CRM
      socket.emit("notificacion-crm", notificacion);
      // Postear notificación en la base de datos
      await axios.post(`${url}/api/baja/notificaciones`, notificacion);
      // Enviar mail con los datos de la baja
      await sendFormMail(inputValues, pasoSiguiente, bajaMail);
      goToTop();
    } catch (error) {
      console.log(error);
    }
  }

  // Obtener campos para el formulario de producto al seleccionarse un bien asegurado en el formulario de baja
  const getCampos = async () => {
    setCamposProducto([]);
    try {
      const campos = await getCamposProductoAnulacion(inputValues.producto);
      const initialValues = {};
      if (campos.data.length > 0) {
        campos.data.forEach((campo) => (initialValues[campo.name_campo] = ""));
      }
      setCamposProducto(campos.data);
      setProductoFormInitialValues(initialValues);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (inputValues.producto) {
      getCampos();
    }
  }, [inputValues.producto]);

  // Obtener información para selects del formulario de baja 
  useEffect(() => {
    const getData = async () => {
      try {
        const productos = await obtenerProductosCalidad();
        const aseguradoras = await obtenerAseguradoras();
        const motivos = await getMotivosBaja();
        const aseguradorasList = aseguradoras.data.map((aseguradora, index) => {
          return {
            label: aseguradora.nombre_compania,
            value: aseguradora.id_compania,
            key: index + 1,
          };
        });
        const productosList = productos.data.map((producto, index) => {
          return {
            label: producto.descripcion,
            value: producto.numero,
            key: index + 1,
          };
        });
        const motivosList = motivos.data.map((motivo, index) => {
          return {
            label: motivo.nombre_motivo_anulacion,
            value: motivo.id_motivo_anulacion,
            key: index + 1,
          };
        });
        setProductos(productosList);
        setAseguradoras(aseguradorasList);
        setMotivos(motivosList);
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, []);

  return {
    formProducto,
    firmaDigitalRef,
    data: { productos, aseguradoras, loading, camposProducto, motivos },
    formBaja: { inputValues, handleChange, handleValidation, formErrors },
  };
}
