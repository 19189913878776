/**
 * Archivo: index.jsx
 * Sistema: CMR
 * Módulo: Baja Form
 * Responsables: NATALIA MOSQUERA - JOSE LUIS CONDORI
 * Programador: Rodrigo Andrade
 * Última modificación: 18 de marzo del 2025
 * Descripción: Formulario para solicitar la baja de una póliza, incluyendo datos personales, del bien asegurado y firma digital.
 */

import Container, { FirmaDigital } from "./styled";
import FormSection from "../../FormSection";
import { BiShield, BiFile, BiUser, BiBox, BiPen, BiEraser } from "react-icons/bi";
import InputBasic from "../../InputBasic";
import Button from "../../Button";
import InputFile from "../../InputFile";
import InputSelect from "../../InputSelect";
import InputTextarea from "../../InputTextarea";
import SignatureCanvas from "react-signature-canvas";

const BajaForm = ({ formValues, formProducto, firmaDigitalRef }) => {
  const {
    inputValues,
    handleChange,
    handleValidation,
    formErrors,
    loading,
    productos,
    aseguradoras,
    camposProducto,
    motivos,
  } = formValues;

  /**
   * Maneja la validación de los formularios de datos personales y del producto.
   */
  const handleValidationForms = (e) => {
    handleValidation(e);
    formProducto.handleValidation(e);
  };
  
  return (
    <Container>
      <form>
        {/* Sección de datos personales */}
        <FormSection
          title="Datos personales"
          description=""
          icon={<BiUser />}
        />
        
        {/* Campos de entrada para datos personales */}
        <InputBasic label="Nombre" type="text" name="nombre" onChange={handleChange} value={inputValues.nombre} className={formErrors.nombre ? "input-error" : ""} error={formErrors.nombre} placeholder="Nombre" />
        <InputBasic label="Apellido" type="text" name="apellido" onChange={handleChange} value={inputValues.apellido} className={formErrors.apellido ? "input-error" : ""} error={formErrors.apellido} placeholder="Apellido" />
        <InputBasic label="DNI" type="number" name="dni" onChange={handleChange} value={inputValues.dni} className={formErrors.dni ? "input-error" : ""} error={formErrors.dni} placeholder="DNI" />
        <InputBasic label="CUIL" type="number" name="cuil" onChange={handleChange} value={inputValues.cuil} className={formErrors.cuil ? "input-error" : ""} error={formErrors.cuil} placeholder="CUIL" />
        <InputBasic label="Correo electrónico" type="email" name="email" onChange={handleChange} value={inputValues.email} className={formErrors.email ? "input-error" : ""} error={formErrors.email} placeholder="correo@gmail.com" />
        <InputBasic label="Teléfono" type="number" name="telefono" onChange={handleChange} value={inputValues.telefono} className={formErrors.telefono ? "input-error" : ""} error={formErrors.telefono} placeholder="Teléfono" />

        {/* Sección de información del bien asegurado */}
        <FormSection title="Datos del bien asegurado" description="Información sobre la póliza que buscas anular" icon={<BiFile />} />
        <InputSelect label="Bien asegurado" name="producto" value={inputValues.producto} onChange={handleChange} options={productos} error={formErrors.producto} placeholder="Producto" />
        <InputBasic label="Número de Póliza" type="number" name="poliza" onChange={handleChange} value={inputValues.poliza} className={formErrors.poliza ? "input-error" : ""} error={formErrors.poliza} placeholder="Número de Póliza" />
        <InputSelect label="Aseguradora" name="aseguradora" value={inputValues.aseguradora} onChange={handleChange} options={aseguradoras} error={formErrors.aseguradora} placeholder="Aseguradora" />

        {/* Sección de datos de la póliza, si aplica */}
        {camposProducto.length > 0 && (
          <>
            <FormSection title="Datos de la Póliza" description="Información sobre el producto asociado a tu póliza" icon={<BiBox />} />
            {camposProducto.map((campo) => (
              campo.tipo_campo === "text" || campo.tipo_campo === "number" ? (
                <InputBasic key={campo.name_campo} type={campo.tipo_campo} name={campo.name_campo} label={campo.label_campo} onChange={formProducto.handleChange} value={formProducto.inputValues[campo.name_campo]} error={formProducto.formErrors[campo.name_campo]} />
              ) : campo.tipo_campo === "file" ? (
                <InputFile key={campo.name_campo} handleChangeExternal={formProducto.handleChangeExternal} archivo={formProducto.inputValues[campo.name_campo]} nombre={campo.name_campo} label={campo.label_campo} error={formProducto.formErrors[campo.name_campo]} />
              ) : null
            ))}
          </>
        )}

        {/* Sección de motivo de anulación */}
        <FormSection title="Motivo de Anulación" description="Contanos el motivo por el cuál querés anular tu póliza" icon={<BiShield />} />
        <InputSelect label="Motivo de Anulación" name="motivo" value={inputValues.motivo} onChange={handleChange} options={motivos} error={formErrors.motivo} placeholder="Motivo" />
        {inputValues.motivo === "5" && (
          <InputTextarea label="Motivo de anulación" name="motivo_anulacion_otros" onChange={handleChange} value={inputValues.motivo_anulacion_otros} error={formErrors.motivo_anulacion_otros} placeholder="Describa el motivo" maxLength={1000} />
        )}

        {/* Sección de firma digital */}
        <FormSection title="Firma Digital" description='Si estás desde tu celular, pasá tu dedo sobre la pantalla, por el recuadro como un "lapiz" para firmar. Si estás desde una computadora, podes dibujar la firma con tu mouse sobre el recuadro' icon={<BiPen />} />
        <FirmaDigital>
          <label className="label-firma-digital">Firma Digital</label>
          <SignatureCanvas penColor="black" canvasProps={{ width: 500, height: 200, className: "sigCanvas" }} ref={firmaDigitalRef} />
          <span className="limpiar-firma-digital" onClick={() => firmaDigitalRef.current.clear()}><BiEraser /> Deshacer firma</span>
        </FirmaDigital>

        {/* Botón de envío del formulario */}
        <Button type="submit" onClick={handleValidationForms} disabled={loading}>
          {loading ? "Enviando..." : "Enviar"}
        </Button>
      </form>
    </Container>
  );
};

export default BajaForm;
