import styled from "styled-components";
import { breakpoints } from "../../../constants/media";

const Container = styled.div`
  width: 100% !important;
  margin: 0px 0px 0px 0px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;

  // Max Width Container
  #max-width-container {
    width: 1140px;
    height: 100%;
    margin: 0px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    align-content: flex-start;
  }

  // Texto Container
  #formulario-container {
    width: 730px;
    margin: 56px 0px 268px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;

    // Título Container
    #titulo-container {
      width: 100%;
      margin: 0px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
      align-content: flex-start;

      // Botón Inicio
      #boton-inicio {
        width: auto;
        margin: 0px 0px 0px 0px;
        padding: 0px 0px 0px 0px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        align-content: center;
        font-weight: 500;
        font-size: 14px;
        line-height: 180%;
        color: var(--verde50);
        transition: 0.2s all;
        cursor: pointer;

        // Span
        span {
          margin: 5px 6px 0px 0px;
          font-size: 20px;
          transition: 0.2s all;
        }

        :hover {
          color: var(--verde90);

          // Span
          span {
            color: var(--verde90);
            transform: translate(-8px, 0px);
          }
        }
      }

      // Titulo
      h3 {
        width: 100%;
        margin: 40px 0px 0px 0px;
        font-weight: 500;
        font-size: 25px;
        text-align: left;
        letter-spacing: -0.025em;
        line-height: 44px;

        // Span
        span {
          color: var(--azul100);
        }
      }

      // Tag
      #tag {
        width: auto;
        margin: 16px 0px 0px 0px;
        font-weight: 500;
        font-size: 13px;
        text-align: center;
        color: var(--verde90);
        line-height: 154.7%;
        text-transform: uppercase;
        letter-spacing: 2px;
      }

      // Texto
      #texto {
        width: 100%;
        margin: 24px 0px 0px 0px;
        display: flex;
        justify-content: flex-start;
        align-content: center;
        align-items: center;
        flex-wrap: wrap;
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 190%;
        text-align: left;
        letter-spacing: -0.015em;
        color: var(--lila90);
      }
    }
  }

  // Separación
  #separacion {
    width: 1px;
    height: 100%;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: inline-flex;
    align-items: center;
    align-content: center;
    justify-content: center;

    hr {
      width: 1px;
      height: 100%;
      margin: 0px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
      border: 1px solid #c9cdd5;
      border-top: 0px !important;
      border-bottom: 0px !important;
      border-right: 0px !important;
      opacity: 0.3;
    }
  }

  // 1710px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1710}) {
    // Max Width Container
    #max-width-container {
      max-width: 1000px;
    }

    // Texto Container
    #formulario-container {
      width: 630px;
      padding: 80px 64px 80px 0px;
    }

    // Input Container
    .input-container {
      width: 35%;
    }

    // Input Teléfono y Email y DNI
    #input-telefono-container,
    #input-email-container,
    #input-dni-container {
      margin: 32px 16px 0px 0px;
    }
  }

  // 1200px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1200}) {
    // Max Width Container
    #max-width-container {
      max-width: 680px;
    }

    // Texto Container
    #formulario-container {
      width: 100%;
      padding: 80px 0px 80px 0px;
      border: 0;
    }

    // Plazos Container
    #plazos-container {
      display: none;
    }
  }

  // 850px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet850}) {
    // Max Width Container
    #max-width-container {
      max-width: 85%;
    }
  }

  // 651px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile651}) {
    // Input Container
    .input-container {
      width: 45%;
    }

    // Title Container
    #titulo-container {
      flex-wrap: wrap;
    }

    #titulo-container h4 {
      width: 100%;
      margin: 0px 0px 0px 0px;
    }

    #titulo-container p {
      margin: 16px 0px 16px 0px;
    }
  }

  // 414px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile414}) {
    // Datos Personales Titulo y Mensaje Motivo Titulo
    #datos-personales h4,
    #mensaje-motivo h4 {
      margin: 0px 0px 8px 0px;
    }

    // Input Container
    .input-container {
      width: 100%;
      margin: 24px 0px 0px 0px;
    }

    // Textarea Container
    #textarea-mensaje-container {
      width: 100%;
      margin: 24px 0px 0px 0px;
    }

    #titulo-container p {
      margin: 16px 0px 0px 0px;
    }

    // Input Teléfono y Email y DNI
    #input-telefono-container,
    #input-email-container,
    #input-dni-container {
      margin: 32px 0px 0px 0px;
    }
  }
`;

export default Container;
